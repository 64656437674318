export default [
  {
    path: '/publicity/:client',
    name: 'all-publicities-client',
    component: () => import('@/views/publicity/AllPublicities/AllPublicities.vue'),
    meta: {
      resource: 'publicity',
      action: 'manage',
    },
  },
  {
    path: '/publicity',
    name: 'all-publicities',
    component: () => import('@/views/publicity/AllPublicities/AllPublicitiesAdmin.vue'),
    meta: {
      resource: 'administrator',
      action: 'manage',
    },
  },
  {
    path: '/display-publicity/:equipment/:client',
    name: 'display-publicity',
    component: () => import('@/views/publicity/DisplayPublicity/DisplayPublicity.vue'),
    meta: {
      resource: 'publicity',
      action: 'read',
    },
  },
]
